<template>
  <div class="add-charger-wrap">
    <div class="header">
      <h3 class="w-title-gradients">招商负责人设置</h3>
      <i
        class="el-icon-close"
        @click="close()"
      ></i>
    </div>
    <div class="content is-scroll-y-black">
      <div class="left-wrap">
        <div class="title">
          <span class="line"></span>
          可选铺位
        </div>
        <div class="select-view">
          <div class="w-input-item w-input-sty">
            <el-input
              v-model="berthNo"
              @input="searchBerthNo"
              placeholder="搜索铺位号"
              clearable
            >
              <i
                slot="prefix"
                class="icon-search-berth"
              ></i>
            </el-input>
          </div>
          <div class="w-input-item w-input-sty">
            <el-select
              popper-class="w-block-select-down yellow-select-panel"
              v-model="formatStr"
              placeholder="请选择业态"
              @change="changeFormat"
              clearable
            >
              <el-option
                v-for="item in formatList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="w-input-item w-input-sty">
            <el-cascader
              popper-class="black-select-panel"
              :options="filterFloorArr"
              v-model="selectFloor"
              placeholder="请选择楼宇楼层"
              change-on-select
              @change="changeFloor"
              ref="myCascader"
              filterable
              clearable
            ></el-cascader>
          </div>
          <div class="w-input-item w-input-sty">
            <el-select
              popper-class="w-block-select-down yellow-select-panel"
              v-model="mainStore"
              placeholder="请选择主次力店"
              @change="changeMainStore"
              clearable
            >
              <el-option
                v-for="item in mainStoreList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div
          class="table-wrap"
          :style="{'height': selectTableHeight + '!important'}"
        >
          <el-table
            ref="table1"
            :height="selectTableHeight"
            :data="leftList"
            tooltip-effect="dark"
            @selection-change="markSelecte"
          >
            <el-table-column
              type="selection"
              width="45"
            >
            </el-table-column>
            <el-table-column
              prop="buildingAndFloor"
              label="楼宇楼层"
            >
            </el-table-column>
            <el-table-column
              prop="berthNo"
              label="铺位号"
            >
            </el-table-column>
            <el-table-column
              prop="berthType"
              label="铺位类型"
            >
              <template slot-scope="scope">
                {{scope.row.berthType === '1' ? '铺位' : (scope.row.berthType === '2' ? '广告位' : (scope.row.berthType === '3' ? '场地' : (scope.row.berthType === '4' ? '写字楼' : '-')))}}
              </template>
            </el-table-column>
            <el-table-column
              prop="brandIndustry"
              label="规划业态"
            >
            </el-table-column>
            <el-table-column
              prop="mainStore"
              label="主次力店"
            >
              <template slot-scope="scope">
                {{scope.row.mainStore === 0 ? '普通店' : (scope.row.mainStore === 1 ? '主力店' : (scope.row.mainStore === 2 ? '次主力店' : '-'))}}
              </template>
            </el-table-column>
            <el-table-column
              width="120"
              prop="brandTaskCount"
              label="意向落位品牌数"
            >
            </el-table-column>
            <el-table-column
              prop="taskState"
              width="100"
              label="招商状态"
            >
            </el-table-column>
            <el-table-column
              prop="principalName"
              width="100"
              label="招商负责人"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="btns-wrap">
        <span
          @click="toLeft"
          :class="[rightMarkList.length ? '' : 'cannot']"
        >
          <i class="icon-page-return"></i>
          到左边
        </span>
        <span
          @click="toRight"
          :class="[leftMarkList.length ? '' : 'cannot']"
        >
          到右边
          <i class="icon-page-enter"></i>
        </span>
      </div>
      <div class="right-wrap">
        <div class="title">
          <span class="line"></span>
          已选铺位
        </div>
        <div class="count">
          共选择：<span>{{berthCount}}</span>个铺位<span>{{areaCount}}</span>㎡<span>{{brandCount}}</span>个品牌
        </div>
        <div
          class="table-wrap"
          :style="{'height': selectedTableHeight + '!important'}"
        >
          <el-table
            ref="table2"
            :height="selectedTableHeight"
            :data="rightList"
            tooltip-effect="dark"
            @selection-change="markSelected"
          >
            <el-table-column
              type="selection"
              width="45"
            >
            </el-table-column>
            <el-table-column
              prop="buildingAndFloor"
              label="楼宇楼层"
            >
            </el-table-column>
            <el-table-column
              prop="berthNo"
              label="铺位号"
            >
            </el-table-column>
            <el-table-column
              prop="brandIndustry"
              label="规划业态"
            >
            </el-table-column>
            <el-table-column
              prop="mainStore"
              label="主次力店"
            >
              <template slot-scope="scope">
                {{scope.row.mainStore === 0 ? '普通店' : (scope.row.mainStore === 1 ? '主力店' : (scope.row.mainStore === 2 ? '次主力店' : '-'))}}
              </template>
            </el-table-column>
            <el-table-column
              prop="taskState"
              label="招商状态"
            >
            </el-table-column>
            <el-table-column
              prop="principalName"
              label="招商负责人"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="select-wrap">
          <div class="pullDownBox">
            <div class="w-input-item w-input-sty mr24">
              <span class="w-type-name">招商负责人<span>* </span></span>
              <el-select
                popper-class="w-block-select-down yellow-select-panel"
                v-model="followId"
                @change="changeFollow"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in followList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">招商时效 </span>
              <el-date-picker
                v-model="time"
                value-format="yyyy-MM-dd"
                type="daterange"
                popper-class="black-el-date-picker"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="w-input-item w-input-sty pb16">
            <span class="w-type-name">
              发布跟进品牌任务
              <el-tooltip
                class="tips"
                effect="dark"
                content="是否将对应铺位中未发布任务的品牌直接发布给对应招商负责人"
                placement="right"
              >
                <span>
                  <i class="icon-help-tips"></i>
                </span>
              </el-tooltip>
            </span>
            <el-switch
              class="releaseBtn"
              v-model="isFabu"
              inactive-color="#424752"
              on-text=""
              off-text=""
            >
            </el-switch>
          </div>
        </div>
      </div>
    </div>
    <div class="info-btn">
      <span
        class="sure"
        @click="sure()"
      >确定</span>
      <span
        class="cancal"
        @click="close()"
      >取消</span>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  props: {
    projectId: {
      type: Number
    },
    formatList: {
      type: Array,
      default: function () {
        return []
      }
    },
    followList: {
      type: Array,
      default: function () {
        return []
      }
    },
    filterFloorArr: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    berthCount () {
      return this.rightList.length
    },
    areaCount () {
      let area = 0
      for (let i = 0; i < this.rightList.length; i++) {
        area = area + this.rightList[i].buildingArea
      }
      return area
    },
    brandCount () {
      let count = 0
      for (let i = 0; i < this.rightList.length; i++) {
        count = count + this.rightList[i].brandTaskCount
      }
      return count
    }
  },
  data () {
    return {
      mainStore: '',
      mainStoreList: [
        {
          label: '主力店',
          value: 1
        },
        {
          label: '次主力店',
          value: 2
        },
        {
          label: '普通店',
          value: 0
        }
      ],
      building: '',
      floor: '',
      selectFloor: [],
      formatStr: '',
      followId: '',
      followName: '',
      berthNo: '', // 铺位号
      allList: [], // 所有数据可选铺位数据
      selectTableHeight: 600, // 左边可选铺位表格高度
      selectedTableHeight: 508, // 右边已选铺位表格高度
      leftList: [], // 左边可选铺位列表数据
      leftMarkList: [], // 左边已勾选的可选铺位列表数据
      rightList: [], // 右边已选铺位列表数据
      rightMarkList: [], // 右边已勾选的已选铺位列表数据
      time: '',
      isFabu: true
    }
  },
  created () {
    this.getList()
  },
  mounted () {
    // 表格自适应高度
    this.$nextTick(function () {
      this.selectTableHeight = window.innerHeight - this.$refs.table1.$el.offsetTop - 180
      this.selectedTableHeight = window.innerHeight - this.$refs.table2.$el.offsetTop - 295
      // 监听窗口大小变化
      const self = this
      window.onresize = function () {
        self.selectTableHeight = window.innerHeight - self.$refs.table1.$el.offsetTop - 180
        self.selectedTableHeight = window.innerHeight - self.$refs.table2.$el.offsetTop - 295
      }
    })
  },
  methods: {
    getList () {
      const params = {
        projectId: this.projectId,
        isPage: 1
      }
      this.axios.post(api.berthTasList, params)
        .then((res) => {
          const arr = res.data.data.dataList.map((item) => {
            const obj = {
              berthId: item.berthId,
              berthIndustry: item.berthIndustry,
              berthNo: item.berthNo,
              berthType: item.berthType,
              brandCount: item.brandCount,
              brandId: item.brandId,
              brandIndustry: item.brandIndustry,
              brandName: item.brandName,
              brandTaskCount: item.brandTaskCount,
              buildingAndFloor: (item.building || item.floor) ? item.building + item.floor : '-',
              building: item.building,
              floor: item.floor,
              buildingArea: item.buildingArea,
              useArea: item.useArea,
              contractCode: item.contractCode,
              contractId: item.contractId,
              principalName: item.principalName,
              gbid: item.gbid,
              mainStore: item.mainStore,
              investmentBrandLevel: item.investmentBrandLevel,
              planBrandNum: item.planBrandNum,
              taskState: item.taskState,
              statusOfUse: item.state,
              berthTaskTime: item.berthTaskTime,
              children: item.children
            }
            return obj
          })
          this.leftList = JSON.parse(JSON.stringify(arr))
          this.allList = JSON.parse(JSON.stringify(arr))
        })
    },
    changeMainStore (val) {
      this.mainStore = val
      this.filterFunc()
    },
    changeFloor (val) {
      this.building = ''
      this.floor = ''
      this.building = val[0]
      if (val[1]) {
        this.floor = val[1]
      }
      this.filterFunc()
    },
    changeFormat (val) {
      this.formatStr = val
      this.filterFunc()
    },
    changeFollow (val) {
      this.followId = val
      const Arr = this.followList.filter(item => {
        return item.value === val
      })
      this.followName = Arr[0].label
    },
    // 筛选函数
    filterFunc () {
      const Arr = JSON.parse(JSON.stringify(this.allList))
      // 筛选条件
      const filter = {
        berthNo: this.berthNo,
        brandIndustry: this.formatStr,
        building: this.building,
        floor: this.floor,
        mainStore: this.mainStore
      }
      // 拿到有值的参数
      const tempFilter = {}
      for (const key in filter) {
        if (typeof (filter[key]) !== 'undefined' && filter[key] != null && filter[key] !== '') {
          tempFilter[key] = filter[key]
        }
      }
      // 筛选
      if (JSON.stringify(tempFilter) !== '{}') {
        this.leftList = Arr.filter((item) => {
          let flag = false
          for (const i in tempFilter) {
            if ((item[i] || item[i] === 0) && item[i].toString().indexOf(tempFilter[i].toString()) >= 0) {
              flag = true
            } else {
              flag = false
              break
            }
          }
          if (flag) {
            return item
          }
        })
      } else {
        this.leftList = Arr.filter(item => {
          return item
        })
      }
      // console.log('筛选后', this.leftList)
    },
    searchBerthNo () {
      this.filterFunc()
    },
    markSelecte (val) {
      this.leftMarkList = val
    },
    markSelected (val) {
      this.rightMarkList = val
    },
    toLeft () {
      // 1.删除左边数组元素
      for (let i = 0; i < this.rightMarkList.length; i++) {
        for (let j = 0; j < this.rightList.length; j++) {
          if (this.rightMarkList[i].berthId === this.rightList[j].berthId) {
            this.rightList.splice(j, 1)
          }
        }
      }
      // 2.删除总数据的元素
      for (let i = 0; i < this.rightMarkList.length; i++) {
        for (let j = 0; j < this.allList.length; j++) {
          if (this.rightMarkList[i].berthId === this.allList[j].berthId) {
            this.allList.splice(j, 1)
          }
        }
      }
      // 3.赋值
      this.leftList.push(...this.rightMarkList)
      this.allList.push(...this.rightMarkList)
    },
    toRight () {
      // 1.删除左边数组元素
      for (let i = 0; i < this.leftMarkList.length; i++) {
        for (let j = 0; j < this.leftList.length; j++) {
          if (this.leftMarkList[i].berthId === this.leftList[j].berthId) {
            this.leftList.splice(j, 1)
          }
        }
      }
      // 2.删除总数据的元素
      for (let i = 0; i < this.leftMarkList.length; i++) {
        for (let j = 0; j < this.allList.length; j++) {
          if (this.leftMarkList[i].berthId === this.allList[j].berthId) {
            this.allList.splice(j, 1)
          }
        }
      }
      // 3.赋值
      this.rightList.push(...this.leftMarkList)
    },
    sure () {
      if (this.rightList.length === 0) {
        this.$message({
          type: 'warning',
          message: '请选择铺位'
        })
        return false
      }
      if (!this.followName) {
        this.$message({
          type: 'warning',
          message: '请选择招商负责人'
        })
        return false
      }
      const params = {
        berthFinishTime: this.time[1],
        berthId: [],
        berthStartTime: this.time[0],
        followUserId: this.followId,
        followUserName: this.followName,
        isRelease: this.isFabu ? 1 : 0,
        projectId: this.projectId,
        sublevelList: []
      }
      params.berthId = this.rightList.map(item => {
        return item.berthId
      })
      params.sublevelList = this.rightList.map(item => {
        const obj = {
          berthId: item.berthId,
          brandId: item.brandId,
          building: item.building,
          floor: item.floor,
          gbid: item.gbid,
          mainStore: item.mainStore,
          projectId: this.projectId,
          children: []
        }
        if (this.isFabu && item.children) {
          obj.children = item.children.filter(item2 => {
            return item2.taskState === '待招商'
          })
        }
        return obj
      })
      // console.log('params', params.sublevelList)
      this.axios.post(api.batchSettingPrincipal, params)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
            this.$emit('setPerson', true)
          } else {
            this.$message({
              type: 'warning',
              message: res.data.msg
            })
          }
        })
    },
    close () {
      this.$emit('hideDrawer', false)
    }
  }
}
</script>

<style lang="stylus" scoped>
.add-charger-wrap
  height 100%
  .header
    display flex
    align-items center
    background-color #272930
    h3
      font-size 16px
      color #fff
    .el-icon-close
      margin-top 12px
      margin-left auto
      margin-right 30px
      color #fff
  .content
    display flex
    justify-content space-between
    padding 32px 24px
    height calc(100% - 50px)
    background #272930
    font-size 16px
    font-family PingFang SC
    font-weight 400
    color #FFF
    .left-wrap
      width 50%
      .select-view
        display flex
        flex-wrap wrap
        justify-content space-between
        .w-input-item
          display flex
          align-items center
          margin-right 0px !important
          margin-bottom 21px
          /deep/ .el-input__inner
            font-size 14px
            width 189px !important
            height 33px !important
        .icon-search-berth
          margin-left 6px
          color #fff
        /deep/ .el-input
          line-height 38px
      .table-wrap
        width 100%
        border 1px solid #373A43
    .btns-wrap
      display flex
      justify-content center
      flex-direction column
      padding auto 32px
      width 82px
      span
        display inline-block
        width 82px
        height 36px
        line-height 36px
        cursor pointer
        text-align center
        background rgba(248, 148, 7, 1)
        border-radius 2px
        font-size 14px
        color rgba(255, 255, 255, 1)
        &:first-child
          margin-bottom 42px
        i
          color #fff
      .cannot
        color rgba(255, 255, 255, 0.5)
        background rgba(248, 148, 7, 0.5)
        i
          color rgba(255, 255, 255, 0.5)
    .no-data
      width 82px
      height 36px
      background-color rgba(248, 148, 7, 0.5)
    .right-wrap
      width 40%
      .count
        margin-bottom 21px
        height 40px
        line-height 40px
        font-size 14px
        font-family PingFang SC
        font-weight bold
        color #808191
        span
          margin-left 8px
          color rgba(248, 148, 7, 1)
      .table-wrap
        border 1px solid #373A43
      .select-wrap
        margin-top 10px
        padding-top 16px
        padding-left 16px
        box-sizing border-box
        width 100%
        background #272930
        box-shadow 0px -4px 16px rgba(0, 0, 0, 0.38)
        border-radius 2px 2px 0px 0px
        .pullDownBox
          display flex
          flex-wrap wrap
          margin-bottom 12px
          width 100%
          /deep/ .w-input-sty .el-input__inner
            width unset
          /deep/ .el-input__inner
            font-size 14px
            width 189px !important
            height 33px !important
          /deep/ .el-range__icon
            display none
          /deep/ .el-range-editor.el-input__inner
            width 225px !important
            color #fff
          /deep/ .el-range-separator
            color #fff
            line-height 27px
          /deep/ .el-range__close-icon
            line-height 33px
          .w-input-item
            margin-bottom 10px
          .w-input-item >>>::-webkit-input-placeholder
            color #808191 !important
          .w-input-item ::-moz-input-placeholder
            color #808191 !important
          .w-input-item ::-ms-input-placeholder
            color #808191 !important
        .w-type-name
          font-size 14px
          font-family PingFang SC
          font-weight 400
          line-height 20px
          color #B2B3BD
          span
            color #f00
        .pb16
          padding-bottom 16px
        .icon-help-tips:before
          font-size 14px !important
        .releaseBtn
          margin-left 20px
    /deep/ .el-table__header-wrapper
      & .el-checkbox
        cursor not-allowed
      & .is-disabled .el-checkbox__inner
        border 1px solid #808191 !important
        cursor not-allowed
      & .is-disabled .el-checkbox__input
        cursor not-allowed
    /deep/ .el-checkbox__inner
      border 1px solid #808191
    /deep/ .el-table th
      color #B2B3BD !important
    /deep/ .el-table tr
      color #808191 !important
    /deep/ .el-table .cell
      font-size 14px
    /deep/ .el-checkbox__inner:after
      border-color #fff
  .title
    margin-bottom 24px
    .line
      margin-right 4px
      display inline-block
      width 2px
      height 14px
      background #F89407
  /deep/ .el-input__icon
    line-height 33px
    height 33px
  // 滚动条的宽度
  /deep/ .el-table__body-wrapper::-webkit-scrollbar
    width 6px // 横向滚动条
    height 6px // 纵向滚动条 必写
  // 滚动条的滑块
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb
    background-color #424752
    border-radius 4px
.info-btn
  position absolute
  bottom 0
  left 0
  display flex
  justify-content center
  align-items center
  width 100%
  height 99px
  border-top 1px solid #373A43
  background #272930
  z-index 100
  span
    display inline-block
    width 130px
    height 36px
    line-height 36px
    text-align center
    color #fff
    background #F89407
    border-radius 4px
    cursor pointer
    &:first-child
      margin-right 28px
    &.sure
      &:hover
        background-color #DB8307
    &.cancal
      background-color #373A43
</style>
<style lang="stylus">
// 隐藏招商负责人选择框的小三角形
.el-popper[x-placement^=bottom] .popper__arrow::after
  border-bottom-color transparent
.el-popper[x-placement^=top] .popper__arrow::after
  border-top-color transparent
.el-popper .popper__arrow, .el-popper .popper__arrow::after
  border-style none !important
</style>
