<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="w-berth-management w-shops-singed-wrap"
  >
    <div class="w-berth-management-wrap">
      <div
        class="w-berth-screen"
        v-if="hasPermission"
      >
        <div class="w-screen-list">
          <div class="w-screen-top">
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">楼宇楼层：</span>
              <el-cascader
                popper-class="black-select-panel"
                :options="filterFloorArr"
                v-model="selectFloor"
                change-on-select
                @change="changeFloor"
                ref="myCascader"
                filterable
                clearable
              ></el-cascader>
            </div>
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">铺位号：</span>
              <input
                class="el-input__inner ml14"
                type="text"
                v-model="berthNo"
                placeholder="请输入铺位号"
              />
            </div>
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">规划业态：</span>
              <el-select
                popper-class="w-block-select-down"
                v-model="formatStr"
                @change="changeFormat"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in formatList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">招商负责人：</span>
              <el-select
                popper-class="w-block-select-down"
                v-model="followId"
                @change="changeFollow"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in followList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="w-screen-bottom">
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">使用状态：</span>
              <el-select
                popper-class="w-block-select-down"
                v-model="statusOfUse"
                @change="changeStatesOfUse"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in statusOfUseList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">招商状态：</span>
              <el-select
                popper-class="w-block-select-down"
                v-model="statesStr"
                @change="changeStates"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in states"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="w-input-item w-input-sty">
              <span class="w-type-name">主次力店：</span>
              <el-select
                popper-class="w-block-select-down"
                v-model="mainStore"
                placeholder="请选择"
                @change="changeMainStore"
                clearable
              >
                <el-option
                  v-for="item in mainStoreList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="w-query-btn"
              @click="searchBtn()"
            >
              <span>查询</span>
            </div>
          </div>
        </div>
      </div>
      <div class="table-view">
        <h3 class="w-title-gradients">铺位招商列表</h3>
        <div
          class="settting fr"
          v-if="hasPermission"
        >
          <span
            class="follower"
            @click="setFollower()"
          >设置招商负责人</span>
          <span
            class="exportOut"
            @click="exportInvestment()"
          >导出
          </span>
        </div>
        <div
          class="w-singed-table"
          v-if="hasPermission"
        >
          <el-table
            class="tbs-black"
            :data="berthList.dataList"
            style="width: 100%"
            :row-class-name="setClassName"
          >
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-form
                  label-position="left"
                  inline
                  class="demo-table-expand"
                  v-for="(item, index) in props.row.children"
                  :key="index"
                >
                  <el-form-item label="意向落位品牌：">
                    <span>{{ item.brandName ? item.brandName : '-' }}</span>
                  </el-form-item>
                  <el-form-item label="品牌业态：">
                    <span>{{ item.typeName ? item.typeName : '-' }}</span>
                  </el-form-item>
                  <el-form-item label="品牌级次：">
                    <span>{{ item.investmentBrandLevel ? item.investmentBrandLevel : '-' }}</span>
                  </el-form-item>
                  <el-form-item label="品牌跟进人：">
                    <span>{{ item.followUserName ? item.followUserName : '-' }}</span>
                  </el-form-item>
                  <el-form-item label="跟进状态：">
                    <span>{{ item.taskState ? item.taskState : '-' }}</span>
                  </el-form-item>
                  <el-form-item label="跟进时间：">
                    <span>{{ item.lastFollowTime ? item.lastFollowTime : '-' }}</span>
                  </el-form-item>
                </el-form>
              </template>
            </el-table-column>
            <el-table-column
              prop="building"
              label="楼宇"
              width="70"
            >
            </el-table-column>
            <el-table-column
              prop="floor"
              label="楼层"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="berthNo"
              label="铺位号"
              width="100"
            >
            </el-table-column>
            <el-table-column
              prop="berthType"
              label="铺位类型"
              width="110"
            >
            </el-table-column>
            <el-table-column
              prop="buildingArea"
              label="建筑面积(㎡)"
              width="130"
            >
            </el-table-column>
            <el-table-column
              prop="useArea"
              label="使用面积(㎡)"
              width="130"
            >
            </el-table-column>
            <el-table-column
              prop="brandIndustry"
              label="规划业态"
              width="140"
            >
            </el-table-column>
            <el-table-column
              prop="mainStore"
              label="主次力店"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="investmentBrandLevel"
              label="招商品牌级次"
              width="130"
            >
            </el-table-column>
            <el-table-column
              prop="statusOfUse"
              label="使用状态"
              width="130"
            >
            </el-table-column>
            <el-table-column
              prop="taskState"
              label="招商状态"
              width="120"
            >
              <template slot-scope="scope">
                <i
                  class="status"
                  :style="{background: scope.row.color}"
                ></i>
                {{scope.row.taskState}}
              </template>
            </el-table-column>
            <el-table-column
              prop="principalName"
              label="招商负责人"
              width="120"
            >
            </el-table-column>
            <el-table-column
              prop="berthTaskTime"
              label="招商时效"
              width="190"
            >
            </el-table-column>
            <el-table-column
              prop="brandTaskCount"
              label="意向落位品牌数"
              width="140"
            >
            </el-table-column>
            <el-table-column
              prop="brandCount"
              label="品牌跟进任务"
              width="120"
            >
            </el-table-column>
            <el-table-column
              label="操作"
              width="120"
              align="center"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button
                  @click="toBerthInvestment(scope.row)"
                  type="text"
                  size="small"
                >查看</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="w-page-sty">
            <el-pagination
              v-if="berthList.total > 10"
              background
              @current-change="pageChange"
              layout="prev, pager, next"
              :page-size="berthList.pageSize"
              :total="berthList.total"
              :current-page="pageNum"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <el-drawer
        size="85%"
        :with-header="false"
        :visible.sync="showDraw"
        direction="rtl"
      >
        <add-person-in-charge
          v-if="showDraw"
          :projectId="projectId"
          :mainStoreList="mainStoreList"
          :formatList="formatListArr"
          :followList="followListArr"
          :filterFloorArr="filterFloorArr"
          @setPerson="setPerson"
          @hideDrawer="hideDrawer"
        ></add-person-in-charge>
      </el-drawer>
    </div>
    <div
      class="simpleInfoPanel"
      v-if="!hasPermission"
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import addPersonInCharge from '@/views/workBench/berthInvestment/components/addPersonInCharge.vue'
import { mapGetters } from 'vuex'
import { getCookie, powerAuth } from '@/utils'
export default {
  name: 'berthInvestmentManage',
  components: {
    addPersonInCharge
  },
  data () {
    return {
      loading: true,
      projectList: [],
      berthList: {},
      selectableList: [], // 本页铺位列表数据
      pageNum: 1,
      data: {},
      formatStr: '',
      formatList: [],
      selectType: '',
      followList: [],
      followId: '',
      mainStore: '',
      mainStoreList: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '主力店',
          value: 1
        },
        {
          label: '次主力店',
          value: 2
        },
        {
          label: '普通店',
          value: 0
        }
      ],
      statesStr: '',
      states: [{
        label: '全部',
        value: ''
      }, {
        label: '待招商',
        value: '待招商'
      }, {
        label: '招商中',
        value: '招商中'
      }, {
        label: '继续招商中',
        value: '继续招商中'
      }, {
        label: '已预定',
        value: '已预定'
      }, {
        label: '已签约',
        value: '已签约'
      }],
      statusOfUse: '',
      statusOfUseList: [{
        label: '全部',
        value: ''
      }, {
        label: '在营',
        value: 2
      }, {
        label: '空置',
        value: 1
      }],
      filterFloorArr: [],
      selectFloor: [],
      building: '',
      floor: '',
      berthNo: '',
      industry: '',
      hasPermission: true, // 权限
      selectProjectObj: {},
      modulesList: [],
      showDraw: false,
      // 传到设置招商负责人组件用
      formatListArr: [],
      followListArr: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    })
  },
  props: {
    projectId: {
      type: Number
    },
    projectName: {
      type: String
    }
  },
  mounted () {
    if (powerAuth(216)) {
      this.getInvestmentList()
      this.getFollow()
      this.getFilterFloorFunc()
      this.getListIndustry()
      this.getModularListFunc()
      this.hasPermission = true
    } else {
      this.hasPermission = false
      this.loading = false
    }
  },
  watch: {
    projectId (newVal, oldVal) {
      // console.log('watch projectId', newVal, oldVal)
      this.getInvestmentList()
      this.getFollow()
      this.getFilterFloorFunc()
      this.getListIndustry()
      this.getModularListFunc()
    }
  },
  methods: {
    setClassName (row) {
      // 通过自己的逻辑返回一个class或者空
      return row.row.expand ? '' : 'disabled-expand'
    },
    getModularListFunc () {
      this.axios.post(api.getModularList)
        .then((res) => {
          this.modulesList = res.data.data.filter(item => {
            return item.id === 12
          })
        })
    },
    hasnotPermission () {
      this.$message.error({
        message: '暂无权限，如需开通权限，请联系管理员开通'
      })
    },
    lookSingleBrand (item) {
      if (this.modulesList[0].flag) {
        if (this.modulesList[0].userFlag) {
          if (item.berthType === '铺位') {
            window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/details/mainBodyInfo?contractCode=' + item.contractCode + '&contractId=' + item.contractId + '&mallCode=' + this.projectId + '&menuCode=12&operateStep=3&tokenval=' + getCookie('wisdom_token'), '_blank')
          } else if (item.berthType === '广告位') {
            window.open(this.$gaodeDomain + '/omsviews/rent/contract/advertContract/details/mainBodyInfo?contractCode=' + item.contractCode + '&contractId=' + item.contractId + '&mallCode=' + this.projectId + '&menuCode=12&operateStep=3&tokenval=' + getCookie('wisdom_token'), '_blank')
          } else if (item.berthType === '场地') {
            window.open(this.$gaodeDomain + '/omsviews/rent/contract/siteContract/details/mainBodyInfo?contractCode=' + item.contractCode + '&contractId=' + item.contractId + '&mallCode=' + this.projectId + '&menuCode=12&operateStep=3&tokenval=' + getCookie('wisdom_token'), '_blank')
          }
          this.statesIdx = null
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，此账号暂无权限！'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，集团暂未开通此应用模块！'
        })
      }
    },
    getListIndustry () {
      this.axios.post(api.getListIndustry1).then((res) => {
        if (res.data.code === 0) {
          this.formatListArr = res.data.data.map(item => {
            return {
              value: item.id,
              label: item.name
            }
          })
          res.data.data.unshift(
            {
              name: '全部',
              id: '',
              value: '全部'
            }
          )
          this.formatList = res.data.data.map(item => {
            return {
              value: item.id,
              label: item.name
            }
          })
        }
      })
    },
    getFollow () {
      this.axios.post(api.getFollowList, { projectId: this.projectId })
        .then((res) => {
          this.followList = res.data.data.map((item) => {
            const obj = {
              value: item.userId,
              label: item.userName
            }
            return obj
          })
          this.followListArr = res.data.data.map((item) => {
            const obj = {
              value: item.userId,
              label: item.userName
            }
            return obj
          })
          this.followList.unshift({ label: '全部', value: '' })
        })
    },
    getFilterFloorFunc (id) {
      this.axios.post(api.getWholeFloorData, { projectId: this.projectId })
        .then((res) => {
          // 只拿楼宇、楼层，过滤掉铺位
          this.filterFloorArr = res.data.data.map(item => {
            const temp = item.children.map(item2 => {
              const obj2 = {
                value: item2.value,
                label: item2.label
              }
              return obj2
            })
            const obj = {
              value: item.value,
              label: item.label,
              children: temp
            }
            return obj
          })
        })
    },
    getInvestmentList () {
      if (JSON.parse(localStorage.getItem('saveScreen'))) {
        this.data = JSON.parse(localStorage.getItem('saveScreen'))
        this.berthNo = this.data.berthNo
        this.industry = this.data.industry
        this.statesStr = this.data.taskState
        this.selectType = this.data.berthIndustryId
        this.formatStr = this.data.brandIndustryId
        this.followId = this.data.principalId
        if (this.data.building) {
          this.$set(this.selectFloor, 0, this.data.building)
          if (this.data.floor) {
            this.$set(this.selectFloor, 1, this.data.floor)
          }
        } else {
          this.selectFloor = []
        }
        this.data.pageSize = 10
        this.data.pageNum = this.pageNum
      } else {
        this.data.pageSize = 10
        // this.data.pageNum = this.pageNum
      }
      this.data.pageNum = this.pageNum
      // console.log('pageNum', this.pageNum)
      this.data.projectId = this.projectId
      this.data.isPage = 0
      this.axios.post(api.berthTasList, this.data)
        .then((res) => {
          this.berthList = res.data.data
          this.berthList.dataList = res.data.data.dataList.map((item, index) => {
            const obj = {
              berthId: item.berthId,
              berthIndustry: item.berthIndustry,
              berthNo: item.berthNo,
              berthType: item.berthType === '1' ? '铺位' : (item.berthType === '2' ? '广告位' : (item.berthType === '3' ? '场地' : (item.berthType === '4' ? '写字楼' : '-'))),
              brandCount: item.brandCount,
              brandId: item.brandId,
              brandIndustry: item.brandIndustry ? item.brandIndustry : '-',
              brandName: item.brandName ? item.brandName : '-',
              brandTaskCount: item.brandTaskCount,
              building: item.building,
              buildingArea: item.buildingArea ? item.buildingArea : '-',
              useArea: item.useArea ? item.useArea : '-',
              contractCode: item.contractCode,
              contractId: item.contractId,
              floor: item.floor ? item.floor : '-',
              principalName: item.principalName ? item.principalName : '-',
              gbid: item.gbid,
              mainStore: item.mainStore === 0 ? '普通店' : (item.mainStore === 1 ? '主力店' : (item.mainStore === 2 ? '次主力店' : '-')),
              investmentBrandLevel: item.investmentBrandLevel ? item.investmentBrandLevel : '-',
              planBrandNum: item.planBrandNum,
              taskState: item.taskState ? item.taskState : '-',
              statusOfUse: item.state ? item.state : '-',
              berthTaskTime: item.berthTaskTime ? item.berthTaskTime : '-',
              color: '',
              children: item.children
            }
            if (item.children && item.children.length) {
              obj.expand = true
            } else {
              obj.expand = false
            }
            if (obj.taskState === '待招商') {
              obj.color = '#FFA134'
            } else if (obj.taskState === '招商中') {
              obj.color = '#F89407'
            } else if (obj.taskState === '继续招商中') {
              obj.color = '#26CBE9'
            } else if (obj.taskState === '已预定') {
              obj.color = '#FE7429'
            } else if (obj.taskState === '已签约') {
              obj.color = '#16DA64'
            } else {
              obj.color = '#999999'
            }
            return obj
          })
          this.selectableList = res.data.data.dataList.map((item) => {
            const obj = {
              berthId: item.berthId,
              berthIndustry: item.berthIndustry,
              berthNo: item.berthNo,
              berthType: item.berthType,
              brandCount: item.brandCount,
              brandId: item.brandId,
              brandIndustry: item.brandIndustry,
              brandName: item.brandName,
              brandTaskCount: item.brandTaskCount,
              buildingAndFloor: (item.building || item.floor) ? item.building + item.floor : '-',
              building: item.building,
              floor: item.floor,
              buildingArea: item.buildingArea,
              useArea: item.useArea,
              contractCode: item.contractCode,
              contractId: item.contractId,
              principalName: item.principalName,
              gbid: item.gbid,
              mainStore: item.mainStore,
              investmentBrandLevel: item.investmentBrandLevel,
              planBrandNum: item.planBrandNum,
              taskState: item.taskState,
              statusOfUse: item.state,
              berthTaskTime: item.berthTaskTime
            }
            return obj
          })
          this.loading = false
        })
    },
    changeFormat (val) {
      this.formatStr = val
    },
    typeChange (val) {
      this.industry = val
    },
    changeStates (val) {
      this.statesStr = val
    },
    changeFollow (val) {
      this.followId = val
    },
    changeStatesOfUse (val) {
      this.statusOfUse = val
    },
    changeMainStore (val) {
      this.mainStore = val
    },
    changeFloor (val) {
      this.building = ''
      this.floor = ''
      this.building = val[0]
      if (val[1]) {
        this.floor = val[1]
      }
    },
    searchBtn () {
      this.loading = true
      this.data.projectId = this.projectId
      this.data.industry = this.industry === '全部' ? '' : this.industry
      this.data.taskState = this.statesStr
      this.data.berthIndustryId = this.selectType
      this.data.brandIndustryId = this.formatStr
      this.data.principalId = this.followId
      this.data.berthNo = this.berthNo
      this.data.state = this.statusOfUse
      this.data.building = this.building
      this.data.floor = this.floor
      this.data.mainStore = this.mainStore
      if (this.selectFloor[0]) {
        this.data.building = this.selectFloor[0]
        if (this.selectFloor[1]) {
          this.data.floor = this.selectFloor[1]
        } else {
          this.data.floor = ''
        }
      } else {
        this.data.building = ''
        this.data.floor = ''
        this.data.berthId = ''
      }
      this.data.pageSize = 10
      this.data.pageNum = 1
      localStorage.setItem('saveScreen', JSON.stringify(this.data))
      this.pageNum = 1
      this.getInvestmentList()
    },
    pageChange (val) {
      this.pageNum = val
      this.getInvestmentList()
    },
    setFollower () {
      if (!powerAuth(216, 'setprincipal')) {
        this.hasnotPermission()
        return false
      }
      this.showDraw = true
    },
    exportInvestment () {
      if (!powerAuth(216, 'leadingout')) {
        this.hasnotPermission()
        return false
      }
      this.loading = true
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      // demo
      this.exportPlan().then(res => { // 处理返回的文件流
        const blob = new Blob([res.data])
        const fileName = '【' + this.projectName + '】' + '铺位招商管理表格' + '【' + year + '-' + month + '-' + day + '】' + '.xlsx'
        // 兼容ie
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
          this.loading = false
        } else {
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          setTimeout(() => {
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
            this.loading = false
          }, 1000)
        }
      })
    },
    exportPlan () {
      const data = {
        berthNo: this.berthNo,
        floor: this.floor,
        followUserId: this.followId,
        industry: this.industry === '全部' ? '' : this.industry,
        pageNum: this.pageNum,
        pageSize: 10,
        projectId: this.projectId,
        taskState: this.statesStr
      }
      return this.axios({ // 用axios发送post请求
        method: 'post',
        url: api.exportBerthList, // 请求地址
        data: data, // 参数
        responseType: 'blob', // 表明返回服务器返回的数据类型
        headers: {
          'Content-Type': 'application/json'
        }
      })
    },
    toBerthInvestment (item) {
      this.$router.push({
        path: '/berthInvestmentManageDetail?id=' + item.berthId
      })
    },
    // 设置招商负责人
    setPerson () {
      this.showDraw = false
      this.getInvestmentList()
    },
    hideDrawer () {
      this.showDraw = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.w-berth-management
  margin-left 204px
  width calc(100% - 226px)
  padding-bottom 16px
  .w-berth-screen
    background #272930
    box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
    border-radius 3px
    padding 40px
    box-sizing border-box
    .w-screen-list
      .w-screen-top
        display flex
        .w-input-item
          display flex
          align-items center
          margin-right 40px
          .el-input__inner
            font-size 14px
          .w-type-name
            display inline-block
            margin-right 4px
            color #fff
            font-size 14px
            font-weight 400
          .ml14
            margin-left 14px
      .w-screen-bottom
        display flex
        margin-top 35px
        .w-input-item
          display flex
          align-items center
          margin-right 40px
          .w-type-name
            display inline-block
            margin-right 4px
            color #fff
            font-size 14px
            font-weight 400
  .table-view
    margin-top 24px
    padding-bottom 24px
    background #272930
    border-radius 3px
    .settting
      margin -14px 25px 15px 0
      .follower
        margin-right 32px
        font-size 16px
        font-weight 400
        color #fff
        cursor pointer
        &:before
          margin-right 6px
          font-family 'iconfont'
          content '\e65b'
        &:hover
          color #f89407
      .exportOut
        font-size 16px
        font-weight 400
        color #fff
        cursor pointer
        &:before
          margin-right 6px
          font-family 'iconfont'
          content '\e658'
        &:hover
          color #f89407
    .w-singed-table
      box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
      box-sizing border-box
      padding-left 32px !important
      padding-right 0px !important
      padding-bottom 35px
      // 表格样式
      & >>> .el-table
        font-size 14px
        font-family Microsoft YaHei
        font-weight 400
      & >>> .el-table th
        color #B2B3BD !important
      & >>> .el-table tr
        color #808191 !important
        & td
          padding 0
          height 51px !important
      // 图标
      & >>> .el-table__expand-icon
        transform rotate(0deg)
        color #FFF
      & >>> .el-table__expand-icon--expanded
        transform rotate(90deg)
      & >>> .el-table__fixed-right::before
        display none
      // 展开行
      & >>> .expanded
        background-color #1E1E23 !important
      & >>> .expanded td
        border-bottom none !important
      & >>> .el-table__expanded-cell
        padding 10px 0 16px 120px !important
        background-color #1E1E23
      & >>> .el-table__expanded-cell td
        padding 0 !important
      & >>> .el-table__expanded-cell:hover
        background-color #1E1E23 !important
      & >>> .el-form-item__label
        color #808191
        padding 0
      & >>> .el-form-item__content
        color #FFF
      & >>> .el-form-item
        margin-right 60px
        margin-bottom 0
      & >>> .el-table__empty-block
        justify-content unset
      .demo-table-expand
        height 36px
        & div
          width 150px
        & div:first-child
          width unset
          /deep/ .el-form-item__content
            width 150px
        & div:nth-child(2)
          width unset
          /deep/ .el-form-item__content
            width 100px
        & div:nth-child(4)
          width unset
          /deep/ .el-form-item__content
            width 100px
        /deep/ .el-form-item__content
          white-space nowrap
          overflow hidden
          text-overflow ellipsis
      .status
        display inline-block
        width 10px
        height 10px
        border-radius 5px
        background #FFA134
        margin-right 5px
</style>
